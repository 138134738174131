// import { MapboxStyleDefinition } from 'mapbox-gl-style-switcher';

// const version = 0.1;

const config = {
  mapboxAccessToken: process.env.REACT_APP_MAPBOX_TOKEN,
  geoserverURL: 'https://geoserver.telesis.at/geoserver',
  lng: 9.782387,
  lat: 47.373631,
  zoom: 11,
  pitch: 0,
  bearing: 0,
  maxPitch: 80,
  maxBounds: [
    [9.55, 47.23], // Southwest coordinates
    [9.99, 47.5], // Northeast coordinates
  ],
  styles: [
    {
      title: 'Straße',
      uri: 'mapbox://styles/mapbox/streets-v11',
    },
    {
      title: 'Luftbild',
      uri: 'mapbox://styles/telesisadmin/cl4pms4lq000t14mlx2b59b3o',
    },
    {
      title: 'Dark',
      uri: 'mapbox://styles/mapbox/dark-v9',
    },
    {
      title: 'Light',
      uri: 'mapbox://styles/mapbox/light-v9',
    },
    {
      title: 'BEV',
      uri: 'https://kataster.bev.gv.at/styles/kataster/style_basic.json',
    },
  ],
  layers: {
    vectorTiles: {
      addSources: [
        // example for adding sources:
        // {
        //   id: 'id_name',
        //   type: 'layer_type', // 'raster', 'vector', 'geojson', ...
        //   tiles: [
        //     `https://example.com/wms?...`,
        //   ],
        //   minzoom: 0,
        //   maxzoom: 21,
        // },
        {
          id: 'Gebäude',
          type: 'vector',
          // use the tiles option to specify a WMTS tile source URL
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/
          tiles: [
            `https://geoserver.telesis.at/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=telesis:Hauptkonturflächen&propertyName=area,geom,id&STYLE=&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
          ],
          minzoom: 15,
          maxzoom: 21,
        },
        {
          id: 'gemeinden',
          type: 'vector',
          tiles: [
            `https://geoserver.telesis.at/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=Hörbranz:gemeinden_vorarlberg&STYLE=&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
          ],
          minzoom: 8,
          maxzoom: 21,
        },
        // {
        //   id: 'kataster',
        //   type: 'vector',
        //   // tiles: [
        //   //   `https://data.bev.gv.at/geoserver/BEVdataKAT/ows?REQUEST=GetMap&SERVICE=WMS&VERSION=1.1.0&LAYER=KAT_DKM_GST-NFL&STYLE=&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
        //   // ],
        //   tiles: ['https://kataster.bev.gv.at/styles/kataster/tiles.json'],
        //   minzoom: 8,
        //   maxzoom: 21,
        // },
        {
          id: 'haltung',
          type: 'vector',
          tiles: [
            `https://geoserver.telesis.at/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=wolfurt%3Ahaltung&STYLE=&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
          ],
          minzoom: 8,
          maxzoom: 21,
        },
        {
          id: 'schacht',
          type: 'vector',
          tiles: [
            `https://geoserver.telesis.at/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=wolfurt%3Aschacht&STYLE=&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
          ],
          minzoom: 8,
          maxzoom: 21,
        },
        {
          id: 'leitung',
          type: 'vector',
          tiles: [
            `https://geoserver.telesis.at/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=wolfurt%3Aleitung&STYLE=&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
          ],
          minzoom: 8,
          maxzoom: 21,
        },
        {
          id: 'grundstueck',
          type: 'vector',
          tiles: [
            `https://geoserver.telesis.at/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=wgdl:grundstuecke&STYLE=&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
          ],
          minzoom: 8,
          maxzoom: 21,
        },
        {
          id: 'bevoelkerung',
          type: 'vector',
          tiles: [
            `https://geoserver.telesis.at/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=dornbirn:bevoelkerung&STYLE=&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
          ],
          minzoom: 8,
          maxzoom: 21,
        },
        {
          id: 'energiebedarf',
          type: 'vector',
          tiles: [
            `https://geoserver.telesis.at/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=dornbirn:energiebedarf&STYLE=&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
          ],
          minzoom: 8,
          maxzoom: 21,
        },
      ],
      addLayers: [
        {
          id: 'Gebäude',
          type: 'fill-extrusion',
          source: 'Gebäude',
          sourceLayer: 'Hauptkonturflächen',
          minzoom: 8,
          layout: {
            // Visibility is set to "none" by default, so we need to set it to "visible"
            visibility: 'visible',
          },
          metaData: {
            'mapbox:group': 'telesis',
          },
          paint: {
            'fill-extrusion-color': '#aaa',
            // Use an 'interpolate' expression to
            // add a smooth transition effect to
            // the Gebäude as the user zooms in.
            'fill-extrusion-height': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'objhoehe'],
            ],
            'fill-extrusion-base': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'min_height'],
            ],
            'fill-extrusion-opacity': 0.8,
          },
        },
        {
          id: 'gemeinden',
          type: 'line',
          source: 'gemeinden',
          sourceLayer: 'gemeinden_vorarlberg',
          minzoom: 9,
          layout: {
            // Visibility is set to "none" by default, so we need to set it to "visible"
            visibility: 'visible',
          },
          metadata: {
            'mapbox:group': 'telesis',
          },
          paint: {
            'line-color': 'red',
            'line-width': 1,
            'line-dasharray': [4, 4],
          },
        },
        // {
        //   id: 'grundstuecke',
        //   type: 'line',
        //   source: 'kataster',
        //   sourceLayer: 'gst',
        //   minzoom: 14,
        //   layout: {
        //     // Visibility is set to "none" by default, so we need to set it to "visible"
        //     visibility: 'visible',
        //   },
        //   filter: ['==', 'rstatus', 'E'],
        //   metaData: {
        //     'mapbox:group': 'telesis',
        //   },
        //   paint: {
        //     'line-color': 'grey',
        //     'line-width': 0.4,
        //   },
        // },
        {
          id: 'haltung', // Layer ID
          type: 'line',
          source: 'haltung', // ID of the tile source created above
          // Source has several layers. We visualize the one with name 'sequence'.
          sourceLayer: 'haltung',
          minzoom: 8,
          layout: {
            // Visibility is set to "none" by default, so we need to set it to "visible"
            visibility: 'none',
            'line-cap': 'round',
            'line-join': 'round',
          },
          metadata: {
            'mapbox:group': 'telesis',
          },
          paint: {
            'line-opacity': 0.7,
            'line-color': [
              'match',
              ['get', 'entwaesserungssystem_id'],
              52,
              '#aa0d1d',
              53,
              '#1400ae',
              51,
              '#ef8507',
              884,
              '#91522d',
              882,
              '#beb297',
              885,
              '#7d8b8f',
              /* other */ '#791f7c',
            ],
            'line-width': 3,
          },
        },
        {
          id: 'schacht', // Layer ID
          type: 'circle',
          source: 'schacht', // ID of the tile source created above
          // Source has several layers. We visualize the one with name 'sequence'.
          sourceLayer: 'schacht',
          minzoom: 8,
          layout: {
            visibility: 'none',
          },
          metadata: {
            'mapbox:group': 'telesis',
          },
          paint: {
            // Make circles larger as the user zooms from z12 to z15 to z22.
            'circle-radius': {
              base: 10,
              stops: [
                [12, 2],
                [15, 5],
                [22, 10],
              ],
            },
            // Color circles by schachtbauzustand, using a `match` expression.
            'circle-color': [
              'match',
              ['get', 'schachtbauzustand'],
              1,
              '#15c03d',
              2,
              '#223b53',
              3,
              '#e55e5e',
              4,
              '#3bb2d0',
              /* other */ '#444',
            ],
          },
        },
        {
          id: 'leitung', // Layer ID
          type: 'line',
          source: 'leitung', // ID of the tile source created above
          // Source has several layers. We visualize the one with name 'sequence'.
          sourceLayer: 'leitung',
          minzoom: 8,
          layout: {
            // Visibility is set to "none" by default, so we need to set it to "visible"
            visibility: 'none',
            'line-cap': 'round',
            'line-join': 'round',
          },
          metadata: {
            'mapbox:group': 'telesis',
          },
          paint: {
            'line-opacity': 0.7,
            'line-color': '#00387b',
            'line-width': 3,
          },
        },
        {
          id: 'grundstueck',
          type: 'line',
          source: 'grundstueck',
          sourceLayer: 'grundstuecke',
          minzoom: 16,
          layout: {
            // Visibility is set to "none" by default, so we need to set it to "visible"
            visibility: 'none',
          },
          // filter: ['==', 'rstatus', 'E'],
          metaData: {
            'mapbox:group': 'telesis',
          },
          paint: {
            'line-opacity': 0.7,
            'line-color': 'red',
            'line-width': 0.4,
          },
        },
        {
          id: 'Grundstücke',
          type: 'symbol',
          source: 'grundstueck',
          sourceLayer: 'grundstuecke',
          minzoom: 16,
          layout: {
            'text-field': ['get', 'gnr'],
            'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
            'text-radial-offset': 0.5,
            'text-justify': 'auto',
            'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
            'text-size': 14,
            'icon-image': ['get', 'icon'],
            visibility: 'none',
          },
          paint: {},
        },
        {
          id: 'hoehenschichten',
          type: 'fill',
          source: 'composite',
          sourceLayer: 'contour',
          minzoom: 8,
          layout: { visibility: 'none' },
          paint: {
            'fill-color': [
              'interpolate',
              ['linear'],
              ['get', 'ele'],
              1199,
              'hsla(0, 0%, 100%, 0)',
              1200,
              'hsla(205, 56%, 29%, 0.7)',
              1525,
              'hsla(179, 52%, 41%, 0.7)',
              1850,
              'hsla(155, 49%, 55%, 0.7)',
              2175,
              'hsla(179, 52%, 41%, 0.7)',
              2500,
              'hsla(205, 56%, 29%, 0.7)',
              2501,
              'hsla(0, 0%, 100%, 0)',
            ],
          },
        },
        {
          id: 'contour-line',
          type: 'line',
          source: 'composite',
          sourceLayer: 'contour',
          minzoom: 11,
          layout: {
            // Visibility is set to "none" by default, so we need to set it to "visible"
            visibility: 'none',
            // 'line-join': 'round',
            // 'line-cap': 'round',
          },
          filter: ['!=', ['get', 'index'], -1],
          metaData: {
            'mapbox:group': 'telesis',
          },
          paint: {
            'line-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              11,
              ['match', ['get', 'index'], [1, 2], 0.15, 0.25],
              12,
              ['match', ['get', 'index'], [1, 2], 0.3, 0.5],
            ],
            'line-color': 'hsl(100, 99%, 19%)',
            'line-width': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              ['match', ['get', 'index'], [1, 2], 0.5, 0.6],
              16,
              ['match', ['get', 'index'], [1, 2], 0.8, 1.2],
            ],
            'line-offset': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              ['match', ['get', 'index'], [1, 2], 1, 0.6],
              16,
              ['match', ['get', 'index'], [1, 2], 1.6, 1.2],
            ],
          },
        },
        {
          id: 'contour-label',
          type: 'symbol',
          source: 'composite',
          sourceLayer: 'contour',
          minzoom: 11,
          filter: [
            'any',
            ['==', ['get', 'index'], 10],
            ['==', ['get', 'index'], 5],
          ],
          layout: {
            // Visibility is set to "none" by default, so we need to set it to "visible"
            visibility: 'none',
            'text-field': ['concat', ['get', 'ele'], ' m'],
            'symbol-placement': 'line',
            'text-pitch-alignment': 'viewport',
            'text-max-angle': 25,
            'text-padding': 5,
            'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
            'text-size': ['interpolate', ['linear'], ['zoom'], 15, 9.5, 20, 12],
          },
          metaData: {
            'mapbox:group': 'telesis',
          },
          paint: {
            'text-color': 'hsl(100, 86%, 21%)',
            'text-halo-width': 1,
            'text-halo-color': 'hsla(35, 16%, 100%, 0.5)',
          },
        },
        {
          id: 'landuse',
          type: 'fill',
          metadata: {
            'mapbox:featureComponent': 'land-and-water',
            'mapbox:group': 'Land & water, land',
          },
          source: 'composite',
          sourceLayer: 'landuse',
          minzoom: 5,
          filter: [
            'all',
            ['>=', ['to-number', ['get', 'sizerank']], 0],
            [
              'match',
              ['get', 'class'],
              ['park', 'airport', 'glacier', 'pitch', 'sand', 'facility'],
              true,
              'residential',
              ['step', ['zoom'], true, 12, false],
              ['agriculture', 'wood', 'grass', 'scrub'],
              true,
              'cemetery',
              true,
              'school',
              true,
              'hospital',
              true,
              'commercial_area',
              true,
              false,
            ],
            [
              '<=',
              [
                '-',
                ['to-number', ['get', 'sizerank']],
                ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0, 18, 14],
              ],
              14,
            ],
          ],
          layout: { visibility: 'none' },
          paint: {
            'fill-color': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              [
                'match',
                ['get', 'class'],
                'park',
                [
                  'match',
                  ['get', 'type'],
                  ['garden', 'playground', 'zoo'],
                  'hsl(99, 35%, 73%)',
                  'hsl(99, 57%, 75%)',
                ],
                'airport',
                'hsl(230, 12%, 92%)',
                'cemetery',
                'hsl(81, 26%, 81%)',
                'glacier',
                'hsl(196, 60%, 85%)',
                'hospital',
                'hsl(340, 21%, 88%)',
                'pitch',
                'hsl(99, 58%, 70%)',
                'sand',
                'hsl(65, 46%, 89%)',
                'school',
                'hsl(50, 46%, 82%)',
                'commercial_area',
                'hsl(50, 60%, 90%)',
                'agriculture',
                'hsl(81, 26%, 81%)',
                ['wood', 'grass', 'scrub'],
                'hsl(81, 25%, 66%)',
                'residential',
                'hsl(35, 11%, 95%)',
                'hsl(35, 13%, 86%)',
              ],
              16,
              [
                'match',
                ['get', 'class'],
                'park',
                [
                  'match',
                  ['get', 'type'],
                  ['garden', 'playground', 'zoo'],
                  'hsl(99, 35%, 73%)',
                  'hsl(99, 57%, 75%)',
                ],
                'airport',
                'hsl(230, 26%, 90%)',
                'cemetery',
                'hsl(81, 26%, 81%)',
                'glacier',
                'hsl(196, 60%, 85%)',
                'hospital',
                'hsl(340, 32%, 90%)',
                'pitch',
                'hsl(99, 58%, 70%)',
                'sand',
                'hsl(65, 46%, 89%)',
                'school',
                'hsl(50, 46%, 82%)',
                'commercial_area',
                'hsl(50, 60%, 90%)',
                'agriculture',
                'hsl(81, 26%, 81%)',
                ['wood', 'grass', 'scrub'],
                'hsl(81, 25%, 66%)',
                'hsl(35, 13%, 86%)',
              ],
            ],
            'fill-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              5,
              ['match', ['get', 'class'], 'residential', 1, 0],
              6,
              [
                'match',
                ['get', 'class'],
                ['agriculture', 'wood', 'grass', 'scrub'],
                0,
                'glacier',
                0.5,
                1,
              ],
              15,
              [
                'match',
                ['get', 'class'],
                ['wood', 'glacier'],
                0.5,
                ['scrub', 'grass'],
                0.2,
                'residential',
                0,
                1,
              ],
            ],
          },
        },
        {
          id: 'gemeinden',
          type: 'line',
          source: 'gemeinden',
          sourceLayer: 'gemeinden_vorarlberg',
          minzoom: 9,
          layout: {
            // Visibility is set to "none" by default, so we need to set it to "visible"
            visibility: 'visible',
          },
          metaData: {
            'mapbox:group': 'telesis',
          },
          paint: {
            'line-color': 'red',
            'line-width': 1,
            'line-dasharray': [4, 4],
          },
        },

        {
          id: 'energiebedarf',
          type: 'fill',
          source: 'energiebedarf',
          sourceLayer: 'energiebedarf',
          minzoom: 9,
          layout: {
            // Visibility is set to "none" by default, so we need to set it to "visible"
            visibility: 'none',
            // 'line-join': 'round',
            // 'line-cap': 'round',
          },
          // filter: ['!=', ['get', 'top'], 300],
          metaData: {
            'mapbox:group': 'telesis',
            // name: 'Population Density',
            // unit: 'k/km²',
            // labels: {
            //   10: 'Custom label for value 10',
            //   other: '< 1k/km²',
          },
          paint: {
            'fill-color': [
              'interpolate',
              ['linear'],
              ['to-number', ['get', 'endenergiebedarf_gesamt_sum']],
              0,
              'hsla(100%,100%,100%,0)',
              50000,
              '#17d9eb',
              100000,
              '#96e87d',
              250000,
              '#fcdd20',
              500000,
              '#f49c20',
              1000000,
              '#eb5b1e',
              2000000,
              '#e31a1c',
              3000000,
              '#880000',
            ],
          },
        },
        {
          id: 'bevoelkerung',
          type: 'fill',
          source: 'bevoelkerung',
          sourceLayer: 'bevoelkerung',
          minzoom: 9,
          layout: {
            // Visibility is set to "none" by default, so we need to set it to "visible"
            visibility: 'none',
            // 'line-join': 'round',
            // 'line-cap': 'round',
          },
          filter: ['!=', ['get', 'sum'], 300],
          metaData: {
            'mapbox:group': 'telesis',
            // name: 'Population Density',
            // unit: 'k/km²',
            // labels: {
            //   10: 'Custom label for value 10',
            //   other: '< 1k/km²',
          },
          paint: {
            'fill-color': [
              'interpolate',
              ['linear'],
              ['get', 'sum'],
              0,
              '#17d9eb',
              2,
              '#57e1b4',
              5,
              '#96e87d',
              10,
              '#d6ef47',
              20,
              '#fcdd20',
              30,
              '#f49c20',
              50,
              '#eb5b1e',
              300,
              '#e31a1c',
              // 301,
              // '#fff',

              // 0,
              // '#17d9eb',
              // 1,
              // '#57e1b4',
              // 3,
              // '#96e87d',
              // 6,
              // '#d6ef47',
              // 11,
              // '#fcdd20',
              // 21,
              // '#f49c20',
              // 31,
              // '#eb5b1e',
              // 51,
              // '#e31a1c',
              // 301,
              // '#fff',
            ],
          },
        },
        {
          id: 'bevoelkerung_extrusion',
          type: 'fill-extrusion',
          source: 'bevoelkerung',
          sourceLayer: 'bevoelkerung',
          minzoom: 8,
          layout: { visibility: 'none' },
          metaData: {
            'mapbox:group': 'telesis',
          },
          paint: {
            'fill-extrusion-color': [
              'interpolate',
              ['linear'],
              ['get', 'sum'],
              0,
              '#17d9eb',
              2,
              '#57e1b4',
              5,
              '#96e87d',
              10,
              '#d6ef47',
              20,
              '#fcdd20',
              30,
              '#f49c20',
              50,
              '#eb5b1e',
              300,
              '#e31a1c',
            ],
            'fill-extrusion-height': [
              'interpolate',
              ['linear'],
              ['get', 'sum'],
              0,
              2,
              5,
              10,
              20,
              30,
              50,
              300,
            ],
          },
        },
        // {
        //   id: 'hoehenschichten',
        //   type: 'fill-extrusion',
        //   source: 'composite',
        //   sourceLayer: 'contour',
        //   minzoom: 8,
        //   layout: { visibility: 'none' },
        //   metaData: {
        //     'mapbox:group': 'telesis',
        //   },
        //   paint: {
        //     'fill-extrusion-color': [
        //       'interpolate',
        //       ['linear'],
        //       ['get', 'ele'],
        //       1000,
        //       '#fff',
        //       1200,
        //       '#ff0000',
        //       1400,
        //       'hsl(39, 100%, 50%)',
        //       1650,
        //       '#f6ff00',
        //       1850,
        //       '#00ff00',
        //       2050,
        //       '#f6ff00',
        //       2300,
        //       'hsl(39, 100%, 50%)',
        //       2500,
        //       'hsl(0, 100%, 50%)',
        //       2700,
        //       'hsl(0, 0%, 100%)',
        //     ],
        //     'fill-extrusion-height': [
        //       'interpolate',
        //       ['linear'],
        //       ['get', 'ele'],
        //       0,
        //       0,
        //       1200,
        //       1200,
        //       2500,
        //       2500,
        //       8840,
        //       8840,
        //     ],
        //   },
        // },
        // {
        //   id: 'hoehenschichten',
        //   type: 'fill',
        //   source: 'hoehenschichten',
        //   sourceLayer: 'hoehenschichten',
        //   minzoom: 11,
        //   layout: {
        //     // Visibility is set to "none" by default, so we need to set it to "visible"
        //     visibility: 'none',
        //     // 'line-join': 'round',
        //     // 'line-cap': 'round',
        //   },
        //   // filter: ['!=', ['get', 'index'], -1],
        //   metaData: {
        //     'mapbox:group': 'telesis',
        //   },
        //   paint: {
        //     'fill-color': 'red',

        //     // [
        //     //   'match',
        //     //   ['get', 'max_elev'],
        //     //   '1200',
        //     //   '#fbb03b',
        //     //   '1300',
        //     //   '#223b53',
        //     //   '1400',
        //     //   '#e55e5e',
        //     //   '1500',
        //     //   '#3bb2d0',
        //     //   '1600',
        //     //   '#3bb2d0',
        //     //   '1700',
        //     //   '#3bb2d0',
        //     //   '1800',
        //     //   '#3bb2d0',
        //     //   '1900',
        //     //   '#3bb2d0',
        //     //   '2000',
        //     //   '#3bb2d0',
        //     //   '2100',
        //     //   '#3bb2d0',
        //     //   '2200',
        //     //   '#3bb2d0',
        //     //   '2300',
        //     //   '#3bb2d0',
        //     //   '2400',
        //     //   '#3bb2d0',
        //     //   '2500',
        //     //   '#3bb2d0',
        //     //   /* other */ '#ccc',
        //     // ],
        //     // 'line-opacity': [
        //     //   'interpolate',
        //     //   ['linear'],
        //     //   ['zoom'],
        //     //   11,
        //     //   ['match', ['get', 'index'], [1, 2], 0.15, 0.25],
        //     //   12,
        //     //   ['match', ['get', 'index'], [1, 2], 0.3, 0.5],
        //     // ],
        //     // 'line-color': 'hsl(100, 99%, 19%)',
        //     // 'line-width': [
        //     //   'interpolate',
        //     //   ['linear'],
        //     //   ['zoom'],
        //     //   13,
        //     //   ['match', ['get', 'index'], [1, 2], 0.5, 0.6],
        //     //   16,
        //     //   ['match', ['get', 'index'], [1, 2], 0.8, 1.2],
        //     // ],
        //     // 'line-offset': [
        //     //   'interpolate',
        //     //   ['linear'],
        //     //   ['zoom'],
        //     //   13,
        //     //   ['match', ['get', 'index'], [1, 2], 1, 0.6],
        //     //   16,
        //     //   ['match', ['get', 'index'], [1, 2], 1.6, 1.2],
        //     // ],
        //   },
        // },
      ],
    },
    // wmsLayer: {
    //   addSources: [
    //     // {
    //     //   id: 'wms-test-source',
    //     //   type: 'raster',
    //     //   tiles: [
    //     //     'https://img.nj.gov/imagerywms/Natural2015?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=Natural2015',
    //     //   ],
    //     //   tileSize: 256,
    //     //   minzoom: 8,
    //     // },
    //     {
    //       id: 'grundstueck',
    //       type: 'raster',
    //       tiles: [
    //         'https://geoserver.telesis.at/geoserver/telesis/wms?service=WMS&version=1.3.0&request=GetMap&layers=telesis%3AGrundstücke&bbox={bbox-epsg-3857}&crs=EPSG:3857&width=256&height=256&styles=telesis:grundstuecke&format=image/png',
    //       ],
    //       tileSize: 256,
    //       minzoom: 16,
    //     },
    //   ],
    //   addLayers: [
    //     //     {
    //     //       id: 'wms-test-layer', // Layer ID
    //     //       type: 'raster',
    //     //       source: 'wms-test-source', // ID of the tile source created above
    //     //       // Source has several layers. We visualize the one with name 'sequence'.
    //     //       // sourceLayer: 'wms-test-layer',
    //     //       minzoom: 6,
    //     //       layout: {
    //     //         visibility: 'visible',
    //     //       },
    //     //       metaData: {
    //     //         'mapbox:group': 'telesis',
    //     //       },
    //     //       paint: {},
    //     //     },
    //     {
    //       id: 'grundstueck', // Layer ID
    //       type: 'raster',
    //       source: 'grundstueck', // ID of the tile source created above
    //       // Source has several layers. We visualize the one with name 'sequence'.
    //       minzoom: 18,
    //       layout: {
    //         visibility: 'visible',
    //       },
    //       metaData: {
    //         'mapbox:group': 'telesis',
    //       },
    //       paint: {},
    //     },
    //   ],
    // },
  },

  // please specify your covered area if you have multiple locations to do waterworks
  areaSwitcher: {
    areas: [
      {
        title: 'Dornbirn',
        latlng: [9.782387, 47.373631],
        zoom: 11,
        pitch: 75,
        bearing: 0,
      },
      {
        title: 'Wolfurt',
        latlng: [9.750004, 47.467419],
        zoom: 14,
        pitch: 75,
        bearing: 0,
      },
      {
        title: 'Alberschwende',
        latlng: [9.83085, 47.4503],
        zoom: 16,
        pitch: 75,
        bearing: 0,
      },

      {
        title: 'Vorarlberg',
        latlng: [9.9904, 47.2219],
        zoom: 9,
        pitch: 75,
        bearing: 0,
      },
      {
        title: 'Globus',
        latlng: [-1.05307, 36.33078],
        zoom: 2,
        pitch: 20,
        bearing: 0,
      },
    ],
  },
  // please specify layer name for showing in legend panel.
  legend: {
    targets: {
      hillshade: 'Hillshade',
      Gebäude: 'Gebäude',
      gemeinden: 'Gemeinden',
      haltung: 'Haltungen',
      schacht: 'Schächte',
      leitung: 'Leitungen',
      grundstueck: 'Grundstücke',
      Grundstücke: 'Grundstücksnummern',
      'contour-line': 'Höhenlinien',
      'contour-label': 'Höhe',
      energiebedarf: 'Energiebedarf',
      bevoelkerung: 'Bevölkerung',
      bevoelkerung_extrusion: 'Bevölkerung 3D',
    },
    options: {
      showDefault: true,
      showCheckbox: true,
      onlyRendered: false,
      reverseOrder: true,
    },
  },
  attribution: '© Telesis',
  // please specify layers' name for showing popup with attributes table.
  popup: {
    target: [
      'Gebäude',
      'gemeinden',
      'Grundstücke',
      'contour-line',
      'contour-label',
      // 'contour-line',
      // 'contour-label',
      // 'contour-fill',
      // 'hoehenschichten',
      'bevoelkerung',
      // 'energiebedarf',
    ],
  },
};
export default config;
