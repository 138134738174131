import { Box, Container, Typography } from '@mui/material';
import React from 'react';

const Legend: React.FC = () => {
  return (
    <>
      {/* First legend */}
      <div id="first-legend" className="legend">
        <Container maxWidth="md" disableGutters={true}>
          <Typography align="center" mb="5px" sx={{ fontWeight: 'bold' }}>
            Hauptwohnsitze
          </Typography>
          {/* 1. Element */}
          <Box display="flex" flexDirection="row" justifyContent="flex start">
            <Box
              sx={{
                width: 20,
                height: 20,
                mr: 1,
                mb: 1,
                backgroundColor: '#17d9eb',

                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            />
            <Box
              sx={{
                color: '#000',
                display: 'inline',
                // fontWeight: 'bold',
                // mx: 1,
                fontSize: '14px',
                textOverflow: 'ellipsis',
              }}
            >
              0
            </Box>
          </Box>
          {/* 2. Element */}
          <Box display="flex" flexDirection="row" justifyContent="flex start">
            <Box
              sx={{
                width: 20,
                height: 20,
                mr: 1,
                mb: 1,
                backgroundColor: '#57e1b4',

                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            />
            <Box
              sx={{
                color: '#000',
                display: 'inline',
                // fontWeight: 'bold',
                // mx: 1,
                fontSize: '14px',
                textOverflow: 'ellipsis',
              }}
            >
              1-2
            </Box>
          </Box>
          {/* 3. Element */}
          <Box display="flex" flexDirection="row" justifyContent="flex start">
            <Box
              sx={{
                width: 20,
                height: 20,
                mr: 1,
                mb: 1,
                backgroundColor: '#96e87d',

                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            />
            <Box
              sx={{
                color: '#000',
                display: 'inline',
                // fontWeight: 'bold',
                // mx: 1,
                fontSize: '14px',
                textOverflow: 'ellipsis',
              }}
            >
              3-5
            </Box>
          </Box>
          {/* 4. Element */}
          <Box display="flex" flexDirection="row" justifyContent="flex start">
            <Box
              sx={{
                width: 20,
                height: 20,
                mr: 1,
                mb: 1,
                backgroundColor: '#d6ef47',

                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            />
            <Box
              sx={{
                color: '#000',
                display: 'inline',
                // fontWeight: 'bold',
                // mx: 1,
                fontSize: '14px',
                textOverflow: 'ellipsis',
              }}
            >
              6-10
            </Box>
          </Box>
          {/* 5. Element */}
          <Box display="flex" flexDirection="row" justifyContent="flex start">
            <Box
              sx={{
                width: 20,
                height: 20,
                mr: 1,
                mb: 1,
                backgroundColor: '#fcdd20',

                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            />
            <Box
              sx={{
                color: '#000',
                display: 'inline',
                // fontWeight: 'bold',
                // mx: 1,
                fontSize: '14px',
                textOverflow: 'ellipsis',
              }}
            >
              11-20
            </Box>
          </Box>
          {/* 6. Element */}
          <Box display="flex" flexDirection="row" justifyContent="flex start">
            <Box
              sx={{
                width: 20,
                height: 20,
                mr: 1,
                mb: 1,
                backgroundColor: '#f49c20',

                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            />
            <Box
              sx={{
                color: '#000',
                display: 'inline',
                // fontWeight: 'bold',
                // mx: 1,
                fontSize: '14px',
                textOverflow: 'ellipsis',
              }}
            >
              21-30
            </Box>
          </Box>
          {/* 7. Element */}
          <Box display="flex" flexDirection="row" justifyContent="flex start">
            <Box
              sx={{
                width: 20,
                height: 20,
                mr: 1,
                mb: 1,
                backgroundColor: '#eb5b1e',

                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            />
            <Box
              sx={{
                color: '#000',
                display: 'inline',
                // fontWeight: 'bold',
                // mx: 1,
                fontSize: '14px',
                textOverflow: 'ellipsis',
              }}
            >
              31-50
            </Box>
          </Box>
          {/* 8. Element */}
          <Box display="flex" flexDirection="row" justifyContent="flex start">
            <Box
              sx={{
                width: 20,
                height: 20,
                mr: 1,
                mb: 1,
                backgroundColor: '#e31a1c',

                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            />
            <Box
              sx={{
                color: '#000',
                display: 'inline',
                // fontWeight: 'bold',
                // mx: 1,
                fontSize: '14px',
                textOverflow: 'ellipsis',
              }}
            >
              51-300
            </Box>
          </Box>
        </Container>
      </div>
      {/* Second legend */}
      <div id="second-legend" className="legend">
        <Container maxWidth="md" disableGutters={true}>
          <Typography align="center" mb="5px" sx={{ fontWeight: 'bold' }}>
            Energiebedarfsdichte (MWh/Jahr)
          </Typography>
          {/* 1. Element */}
          <Box display="flex" flexDirection="row" justifyContent="flex start">
            <Box
              sx={{
                width: 20,
                height: 20,
                mr: 1,
                mb: 1,
                backgroundColor: '#17d9eb',

                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            />
            <Box
              sx={{
                color: '#000',
                display: 'inline',
                // fontWeight: 'bold',
                // mx: 1,
                fontSize: '14px',
                textOverflow: 'ellipsis',
              }}
            >
              &lt; 50
            </Box>
          </Box>
          {/* 2. Element */}
          <Box display="flex" flexDirection="row" justifyContent="flex start">
            <Box
              sx={{
                width: 20,
                height: 20,
                mr: 1,
                mb: 1,
                backgroundColor: '#96e87d',

                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            />
            <Box
              sx={{
                color: '#000',
                display: 'inline',
                // fontWeight: 'bold',
                // mx: 1,
                fontSize: '14px',
                textOverflow: 'ellipsis',
              }}
            >
              50-100
            </Box>
          </Box>
          {/* 3. Element */}
          <Box display="flex" flexDirection="row" justifyContent="flex start">
            <Box
              sx={{
                width: 20,
                height: 20,
                mr: 1,
                mb: 1,
                backgroundColor: '#fcdd20',

                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            />
            <Box
              sx={{
                color: '#000',
                display: 'inline',
                // fontWeight: 'bold',
                // mx: 1,
                fontSize: '14px',
                textOverflow: 'ellipsis',
              }}
            >
              100-250
            </Box>
          </Box>
          {/* 4. Element */}
          <Box display="flex" flexDirection="row" justifyContent="flex start">
            <Box
              sx={{
                width: 20,
                height: 20,
                mr: 1,
                mb: 1,
                backgroundColor: '#f49c20',

                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            />
            <Box
              sx={{
                color: '#000',
                display: 'inline',
                // fontWeight: 'bold',
                // mx: 1,
                fontSize: '14px',
                textOverflow: 'ellipsis',
              }}
            >
              250-500
            </Box>
          </Box>
          {/* 5. Element */}
          <Box display="flex" flexDirection="row" justifyContent="flex start">
            <Box
              sx={{
                width: 20,
                height: 20,
                mr: 1,
                mb: 1,
                backgroundColor: '#eb5b1e',

                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            />
            <Box
              sx={{
                color: '#000',
                display: 'inline',
                // fontWeight: 'bold',
                // mx: 1,
                fontSize: '14px',
                textOverflow: 'ellipsis',
              }}
            >
              500-1.000
            </Box>
          </Box>
          {/* 6. Element */}
          <Box display="flex" flexDirection="row" justifyContent="flex start">
            <Box
              sx={{
                width: 20,
                height: 20,
                mr: 1,
                mb: 1,
                backgroundColor: '#e31a1c',

                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            />
            <Box
              sx={{
                color: '#000',
                display: 'inline',
                // fontWeight: 'bold',
                // mx: 1,
                fontSize: '14px',
                textOverflow: 'ellipsis',
              }}
            >
              1.000-2.000
            </Box>
          </Box>
          {/* 7. Element */}
          <Box display="flex" flexDirection="row" justifyContent="flex start">
            <Box
              sx={{
                width: 20,
                height: 20,
                mr: 1,
                mb: 1,
                backgroundColor: '#880000',

                // '&:hover': {
                //   backgroundColor: 'primary.main',
                //   opacity: [0.9, 0.8, 0.7],
                // },
              }}
            />
            <Box
              sx={{
                color: '#000',
                display: 'inline',
                // fontWeight: 'bold',
                // mx: 1,
                fontSize: '14px',
                textOverflow: 'ellipsis',
              }}
            >
              &gt; 2.000
            </Box>
          </Box>
        </Container>
      </div>
      <div id="third-legend" className="legend">
        <Container maxWidth="sm" />
      </div>
      <div id="fourth-legend" className="legend">
        <Container maxWidth="sm" />
      </div>
    </>
  );
};
export default Legend;
