const changeCheckboxTypes = () => {
  // const getCurrentCheckbox = document.querySelectorAll('input[type=checkbox]');

  // const nodes = getCurrentCheckbox;
  // for (let i = 0; i < nodes.length; i++) {
  //   if (!nodes[i]) {
  //     continue;
  //   }

  //   if (nodes.length > 0) {
  //     const label = document.createElement('label');
  //     label.className = 'check-text';
  //     nodes[i].appendChild(label);
  //     //   nodes[i].parentNode?.insertBefore(label, nodes[i]);
  //     //   label.appendChild(nodes[i]);
  //   }
  // }

  const label = document.createElement('label');
  var getCurrentCheckbox = document.querySelectorAll('input[type=checkbox]')[0];
  label.className = 'check-text';
  getCurrentCheckbox?.parentNode?.insertBefore(label, getCurrentCheckbox);
  label.appendChild(getCurrentCheckbox);

  // Opened Eye
  const span = document.createElement('span');
  span.className = 'checkboxEye checked';
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const svgPath1 = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path',
  ); //Create a path in SVG's namespace
  const svgPath2 = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path',
  ); //Create a path in SVG's namespace
  svg.setAttribute('aria-hidden', 'true');
  svg.setAttribute('viewbox', '0 0 15 15');
  svg.setAttribute('width', '15px');
  svg.setAttribute('height', '15px');
  svgPath1.setAttribute('d', 'M16 2h-2v-2h-2v2h-2v2h2v2h2v-2h2z'); //Set path's data
  svgPath2.setAttribute(
    'd',
    'M13.498 6.969c0.288 0.32 0.55 0.665 0.782 1.031-0.598 0.946-1.395 1.749-2.335 2.348-1.181 0.753-2.545 1.152-3.944 1.152s-2.763-0.398-3.945-1.152c-0.94-0.6-1.736-1.403-2.335-2.348 0.598-0.946 1.395-1.749 2.335-2.348 0.061-0.039 0.123-0.077 0.185-0.114-0.156 0.427-0.241 0.888-0.241 1.369 0 2.209 1.791 4 4 4s4-1.791 4-4c0-0.011-0-0.022-0-0.032-1.708-0.44-2.973-1.979-2.999-3.817-0.329-0.037-0.662-0.057-1.001-0.057-3.489 0-6.514 2.032-8 5 1.486 2.968 4.511 5 8 5s6.514-2.032 8-5c-0.276-0.55-0.604-1.069-0.979-1.548-0.457 0.268-0.973 0.449-1.523 0.517zM6.5 5c0.828 0 1.5 0.672 1.5 1.5s-0.672 1.5-1.5 1.5-1.5-0.672-1.5-1.5 0.672-1.5 1.5-1.5z',
  ); //Set path's data

  svgPath1.style.fill = '#333'; //Set fill color
  svgPath2.style.fill = '#333'; //Set fill color
  //   svgPath.style.stroke = '#000'; //Set stroke colour
  //   svgPath.style.strokeWidth = '1px'; //Set stroke width

  label.appendChild(span);
  //   getCurrentCheckbox?.parentNode?.insertBefore(
  //     span,
  //     getCurrentCheckbox.nextSibling,
  //   );
  span.appendChild(svg);
  svg.appendChild(svgPath1);
  svg.appendChild(svgPath2);

  // Closed Eye

  const span2 = document.createElement('span');
  span2.className = 'checkboxEye unchecked';
  const svg2 = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const svg2Path1 = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path',
  ); //Create a path in SVG's namespace
  const svg2Path2 = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path',
  ); //Create a path in SVG's namespace
  //   const svg2Path3 = document.createElementNS(
  //     'http://www.w3.org/2000/svg',
  //     'path',
  //   ); //Create a path in SVG's namespace
  svg2.setAttribute('aria-hidden', 'true');
  svg2.setAttribute('width', '15px');
  svg2.setAttribute('viewbox', '0 0 15 15');
  svg2.setAttribute('height', '15px');
  svg2Path1.setAttribute('d', 'M10 2h6v2h-6v-2z'); //Set path's data
  svg2Path2.setAttribute(
    'd',
    'M13.599 5h-4.599v-1.944c-0.328-0.037-0.662-0.056-1-0.056-3.489 0-6.514 2.032-8 5 1.486 2.968 4.511 5 8 5s6.514-2.032 8-5c-0.584-1.167-1.407-2.189-2.401-3zM6.5 5c0.828 0 1.5 0.672 1.5 1.5s-0.672 1.5-1.5 1.5-1.5-0.672-1.5-1.5 0.672-1.5 1.5-1.5zM11.944 10.348c-1.181 0.753-2.545 1.152-3.944 1.152s-2.763-0.398-3.945-1.152c-0.94-0.6-1.736-1.403-2.335-2.348 0.598-0.946 1.395-1.749 2.335-2.348 0.061-0.039 0.123-0.077 0.185-0.114-0.156 0.427-0.241 0.888-0.241 1.369 0 2.209 1.791 4 4 4s4-1.791 4-4c0-0.481-0.085-0.942-0.241-1.369 0.062 0.037 0.124 0.075 0.185 0.114 0.94 0.6 1.737 1.403 2.335 2.348-0.598 0.946-1.395 1.749-2.335 2.348z',
  ); //Set path's data
  //   svg2Path3.setAttribute(
  //     'd',
  //     'M12.969 4.531l-1.084 1.084c0.020 0.012 0.040 0.024 0.059 0.037 0.94 0.6 1.737 1.403 2.335 2.348-0.598 0.946-1.395 1.749-2.335 2.348-1.181 0.753-2.545 1.152-3.944 1.152-0.604 0-1.202-0.074-1.781-0.219l-1.201 1.201c0.933 0.335 1.937 0.518 2.982 0.518 3.489 0 6.514-2.032 8-5-0.703-1.405-1.752-2.6-3.031-3.469z',
  //   ); //Set path's data
  svg2Path1.style.fill = '#333'; //Set fill color
  //   svgPath.style.stroke = '#000'; //Set stroke colour
  //   svgPath.style.strokeWidth = '1px'; //Set stroke width

  //   var getCurrentCheckbox = document.getElementsByClassName(
  //     'mapboxgl-legend-onlyRendered-checkbox',
  //   )[0];

  //   getCurrentCheckbox?.parentNode?.insertBefore(
  //     span2,
  //     getCurrentCheckbox.nextSibling,
  //   );

  label.appendChild(span2);
  span2.appendChild(svg2);
  svg2.appendChild(svg2Path1);
  svg2.appendChild(svg2Path2);
  //   svg2.appendChild(svg2Path3);
};
export default changeCheckboxTypes;
