import React from 'react';

type LogoProps = {};

const Logo: React.FC<LogoProps> = () => {
  return (
    <div className="logo-main">
      <img
        alt="Logo"
        src={`${process.env.PUBLIC_URL}/assets/images/logo_navbar.png`}
        height="50px"
      />
    </div>
  );
};
export default Logo;
