const changeText = () => {
  // const onlyRendered = document.querySelectorAll(
  //   '.mapboxgl-legend-onlyRendered-label',
  // );
  // if
  // const legendTitle = document.querySelectorAll('.mapboxgl-legend-title-label');
  // onlyRendered[0].textContent = 'Nur angezeigte Layer';
  // legendTitle[0].textContent = 'Layer';

  const onlyRendered = document.querySelectorAll(
    '.mapboxgl-legend-onlyRendered-label',
  );
  const legendTitle = document.querySelectorAll('.mapboxgl-legend-title-label');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (document.querySelectorAll('input[type=checkbox]')[0].checked) {
    onlyRendered[0].textContent = 'Nur angezeigte Layer';
  } else {
    onlyRendered[0].textContent = 'Alle Layer';
  }

  legendTitle[0].textContent = 'Layer';
};
export default changeText;
